import React from "react";
import "./Hero.css"; // Ensure Hero.css contains the styles below

const Hero = () => {
  return (
    <div className="page-transition">
      <div className="hero">
        <div className="hero-content">
          <h1>Your Business Deserves a Website That Works as Hard as You Do</h1>
          <h2>
            We build stunning, ROI-driven websites for small businesses in just
            one week.
          </h2>

          <ul className="value-proposition">
            <li>
              🚀 <em>Launch Ready:</em> Get your custom website live in 7 days.
            </li>
            <li>
              📱 <em>Mobile-Optimized:</em> Designs that perform flawlessly on
              any device.
            </li>
            <li>
              💼 <em>Tailored for You:</em> Solutions built to fit small
              business budgets and needs.
            </li>
          </ul>
          <div className="hero-buttons">
            <a
              href="/services"
              className="hero-button primary"
              aria-label="Discover our services"
            >
              Discover Services
            </a>
            <a
              href="/contact"
              className="hero-button secondary"
              aria-label="Get a free consultation"
            >
              Free Consultation
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
