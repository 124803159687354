import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faChartLine,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import "./Services.css";

const Services = () => {
  return (
    <section className="services">
      <div className="services-header">
        <h1>Our Services</h1>
        <p>
          Invest in your business with solutions designed to deliver results.
        </p>
      </div>
      <div className="services-container">
        <div className="service">
          <FontAwesomeIcon icon={faCode} className="service-icon" />
          <h3>Custom Websites</h3>
          <p>
            Launch a stunning, mobile-friendly website in just 7 days. We build
            platforms that attract customers and grow your business.
          </p>
          <Link to="/services/custom-websites" className="service-btn">
            Learn More
          </Link>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faChartLine} className="service-icon" />
          <h3>eCommerce Solutions</h3>
          <p>
            Sell online effortlessly with professional eCommerce setups on
            platforms like Shopify and WooCommerce.
          </p>
          <Link to="/services/ecommerce" className="service-btn">
            Learn More
          </Link>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faRocket} className="service-icon" />
          <h3>Brand Strategy Consulting</h3>
          <p>
            Maximize your online potential with expert guidance tailored to
            small businesses looking to grow.
          </p>
          <Link to="/services/consulting" className="service-btn">
            Learn More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Services;
