import React from "react";
import "./Testimonials.css";

const Testimonials = () => {
  const testimonials = [
    {
      text: "Charles is a fantastic developer. His attention to detail and commitment to quality are exceptional.",
      author: "John, Small Business Owner",
    },
    {
      text: "Working with Charles was a great experience. He delivered on time and exceeded our expectations.",
      author: "Megan, Marketing Manager",
    },
    {
      text: "I highly recommend Charles for any software development needs. He is knowledgeable, reliable, and a pleasure to work with.",
      author: "Michael, Project Lead",
    },
  ];

  return (
    <section className="testimonials">
      <div className="testimonials-header">
        <h1>What Clients Say</h1>
        <p>
          Don’t just take our word for it—hear what our clients have to say
          about their experience.
        </p>
      </div>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <p className="testimonial-text">“{testimonial.text}”</p>
            <h3 className="testimonial-author">{testimonial.author}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
