import React from "react";
import Services from "./Services";
import CreatePackage from "./createPackage";
import "./ServicesPage.css";

const ServicesPage = () => {
  return (
    <div className="page-transition">
      <div>
        <Services />
      </div>
    </div>
  );
};

export default ServicesPage;
