import React from "react";
import { Link } from "react-router-dom";
import "./SEOPage.css"; // Ensure you have this CSS file

const SEOPage = () => {
  return (
    <div className="seo-page">
      <header className="seo-header">
        <h1>Our SEO Services</h1>
        <p>
          Improve your website's visibility and drive more traffic with our
          tailored SEO strategies.
        </p>
      </header>

      <section className="seo-services">
        <div className="service-card">
          <h2>Basic SEO</h2>
          <p>
            Perfect for startups or smaller websites looking to boost their
            online visibility with essential SEO practices.
          </p>
          <ul>
            <li>Keyword Research</li>
            <li>On-page SEO (Titles, Meta Descriptions)</li>
            <li>Google Analytics Setup</li>
            <li>Basic Website Audit</li>
          </ul>
          <p className="price">$199 / month</p>
          <Link to="/contact">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>

        <div className="service-card">
          <h2>Advanced SEO</h2>
          <p>
            For businesses that want to scale up their online presence with more
            advanced SEO techniques and higher ROI.
          </p>
          <ul>
            <li>Comprehensive Keyword Strategy</li>
            <li>Content Optimization</li>
            <li>Link Building</li>
            <li>Monthly Reporting</li>
            <li>Advanced Website Audit</li>
          </ul>
          <p className="price">$399 / month</p>
          <Link to="/contact">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>

        <div className="service-card">
          <h2>Enterprise SEO</h2>
          <p>
            Our all-in-one SEO plan for large websites or businesses seeking
            extensive, tailored SEO strategies for maximum growth.
          </p>
          <ul>
            <li>Custom SEO Strategy</li>
            <li>Comprehensive Analytics & Reporting</li>
            <li>Link Building & Backlink Strategy</li>
            <li>Content Strategy & Development</li>
            <li>24/7 Support</li>
          </ul>
          <p className="price">$799 / month</p>
          <Link to="/contact">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>
      </section>

      <section className="seo-footer">
        <h3>Why Choose Us for SEO?</h3>
        <p>
          Our SEO experts combine technical expertise with creative strategies
          to ensure your website ranks higher and attracts quality traffic.
        </p>
        <Link to="/contact">
          <button className="cta-button">Contact Us Today</button>
        </Link>
      </section>
    </div>
  );
};

export default SEOPage;
