import React from "react";
import "./Projects.css";

const Projects = () => {
  const projects = [
    {
      imgSrc: "../assets/Starquilts247Landing.png",
      alt: "Starquilts247 website screenshot",
      title: "Starquilts247",
      description:
        "Designed and developed a responsive website showcasing exquisite quilts with a photo gallery and SEO optimization.",
    },
    {
      imgSrc: "../assets/StarTrailsDesign.png",
      alt: "Startrails app screenshot",
      title: "Startrails",
      description:
        "Developed a mobile app for promoting tourism in Eastern Montana with a robust database and interactive map.",
    },
    {
      imgSrc: "../assets/SpectralClustering.png",
      alt: "Spectral clustering visualization",
      title: "Spectral Clustering",
      description:
        "Utilized Python and machine learning to uncover patterns and predict trends through spectral clustering techniques.",
    },
    {
      imgSrc: "../assets/eventPlanner.webp",
      alt: "Campus Event Planner app screenshot",
      title: "Campus Event Planner",
      description:
        "Developed a React Native app to streamline event planning and management within the campus community using Firebase.",
    },
  ];

  return (
    <section className="projects">
      <div className="projects-header">
        <h1>Most Recent Projects</h1>
        <p>
          Explore projects that showcase my commitment to quality and
          innovation.
        </p>
      </div>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project" key={index}>
            <img
              src={project.imgSrc}
              alt={project.alt}
              className="project-image"
            />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
      <div className="projects-button-container">
        <a href="/my-work" className="projects-button">
          See All Projects
        </a>
      </div>
    </section>
  );
};

export default Projects;
