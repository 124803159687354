import React from "react";
import "./ServiceDetails.css";
import Projects from "./Projects";
import { Link } from "react-router-dom";

const Ecommerce = () => {
  return (
    <section className="service-details">
      <div className="header">
        <h1>eCommerce Solutions</h1>
        <p>
          Transform your business with powerful eCommerce platforms. Sell
          products, manage inventory, and provide seamless shopping experiences
          for your customers.
        </p>
      </div>
      <div className="content">
        <h2>What We Offer</h2>
        <p>
          Whether you're starting from scratch or upgrading your current online
          store, our services are tailored to fit your business model:
        </p>
        <ul className="benefits-list">
          <li> Professional Store Setup (Shopify, WooCommerce, etc.)</li>
          <li> Custom Design and Branding</li>
          <li> Payment Gateway Integration</li>
          <li> Inventory Management Tools</li>
          <li> Mobile-Friendly Shopping Experience</li>
        </ul>
        <h2>Our Process</h2>
        <div className="process-steps">
          <div className="step">
            <h3>1. Consultation & Planning</h3>
            <p>
              We evaluate your business needs, recommend the best platform, and
              plan the design and functionality of your online store.
            </p>
          </div>
          <div className="step">
            <h3>2. Design & Development</h3>
            <p>
              Our team crafts a user-friendly, visually appealing store that
              matches your brand and encourages conversions.
            </p>
          </div>
          <div className="step">
            <h3>3. Integration & Testing</h3>
            <p>
              From payment gateways to inventory systems, we integrate all
              essential tools and rigorously test your site for functionality.
            </p>
          </div>
          <div className="step">
            <h3>4. Launch & Optimization</h3>
            <p>
              Your store goes live with ongoing support and optimization to
              ensure peak performance.
            </p>
          </div>
        </div>
        <Projects></Projects>
        <h2>Pricing</h2>
        <p>
          We offer flexible pricing plans to suit businesses of all sizes.
          Schedule a consultation to get a personalized quote.
        </p>
        <Link to={"/pricing"}>
          <button className="cta-button">Request a Quote</button>
        </Link>
        <Link to={"/contact"}>
          <button className="cta-button">Schedule a Consultation</button>
        </Link>
      </div>
    </section>
  );
};

export default Ecommerce;
