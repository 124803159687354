import React from "react";
import "./AboutSnippet.css";

const AboutSnippet = () => {
  return (
    <section className="about-snippet">
      <div className="about-snippet-header">
        <h2>
          Transforming Your Ideas into Digital Reality with{" "}
          <b>Innonative Solutions</b>
        </h2>
      </div>
      <div className="about-snippet-content">
        <p>
          At <b>Innonative Solutions LLC</b>, your success drives everything we
          do. Founded by Charles Werk, we specialize in creating innovative
          websites, mobile apps, and software solutions tailored to your unique
          goals. Whether you're a small business eager to expand or a community
          seeking a stronger digital presence, we bring your vision to life.
        </p>
        <p>
          Let’s partner to build digital experiences that captivate your
          audience and deliver real results. With a focus on responsive design,
          fast deployment, and personalized service, we’re here to make your
          ideas shine. Together, we can create something extraordinary.
        </p>
      </div>
    </section>
  );
};

export default AboutSnippet;
