import React from "react";
import "./CTA.css";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <section className="cta">
      <h1>Let’s Build a Website That Drives Results!</h1>
      <p>
        At Innonative Solutions LLC, we specialize in creating websites that do
        more than just look great—they help your business connect with customers
        and grow. Whether you're starting fresh or ready for a redesign, we’ll
        deliver a solution tailored to your goals.
      </p>
      <p className="cta-urgency">
        Limited openings available—launch your new website in just 7 days!
      </p>
      <div className="cta-buttons">
        <Link to="/contact" className="cta-button primary">
          Schedule Your Free Consultation
        </Link>
        <Link to="/pricing" className="cta-button secondary">
          Explore Pricing Options
        </Link>
      </div>
    </section>
  );
};

export default CTA;
