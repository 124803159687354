import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "./PricingForm.css";

const PricingForm = ({ packageName }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    selectedPackage: packageName || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.selectedPackage) {
      alert("Please select a package.");
      return;
    }

    try {
      await addDoc(collection(db, "leads"), {
        ...formData,
        timestamp: serverTimestamp(),
      });
      setFormSubmitted(true);
      alert("Thank you! Your inquiry has been sent.");
      setFormData({
        name: "",
        email: "",
        message: "",
        selectedPackage: packageName || "",
      });
    } catch (error) {
      console.error("Error submitting lead: ", error);
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <section className="pricing-form-section">
      <h2>Interested? Let's Talk! {packageName}</h2>
      {formSubmitted ? (
        <p className="success-message">
          Thank you! Your inquiry has been submitted. We'll get back to you
          soon.
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="pricing-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message (Optional)</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Tell us more about your project..."
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="selectedPackage">Selected Package</label>
            <select
              id="selectedPackage"
              name="selectedPackage"
              value={formData.selectedPackage}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a Package
              </option>
              <option value="Starter Website">Starter Website</option>
              <option value="Growth Website">Growth Website</option>
              <option value="Premium Website">Premium Website</option>
            </select>
          </div>
          <button type="submit" className="cta-button">
            Submit Inquiry
          </button>
        </form>
      )}
    </section>
  );
};

export default PricingForm;
