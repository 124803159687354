import React from "react";
import "./ServiceDetails.css";
import Projects from "./Projects";
import { Link } from "react-router-dom";

const CustomWebsites = () => {
  return (
    <section className="service-details">
      <div className="header">
        <h1>Custom Websites</h1>
        <p>
          Your website is the cornerstone of your online presence. Let us help
          you create a site that not only looks amazing but also drives traffic,
          converts visitors, and grows your business.
        </p>
      </div>
      <div className="content">
        <h2>Why Choose Us?</h2>
        <p>
          We understand the unique challenges faced by small businesses. That’s
          why our websites are:
        </p>
        <ul className="benefits-list">
          <li> Mobile-Responsive</li>
          <li> Search Engine Optimized (SEO)</li>
          <li> Fast Loading for Better User Experience</li>
          <li> Designed with Your Business Goals in Mind</li>
        </ul>
        <h2>Our Process</h2>
        <div className="process-steps">
          <div className="step">
            <h3>1. Initial Consultation</h3>
            <p>
              We discuss your goals, target audience, and preferences to ensure
              we’re aligned with your vision.
            </p>
          </div>
          <div className="step">
            <h3>2. Design Mockups</h3>
            <p>
              Our team creates wireframes and design prototypes tailored to your
              branding and business identity.
            </p>
          </div>
          <div className="step">
            <h3>3. Development & Testing</h3>
            <p>
              Using the latest technologies, we build your website and
              rigorously test it for performance, compatibility, and usability.
            </p>
          </div>
          <div className="step">
            <h3>4. Launch & Support</h3>
            <p>
              We launch your site and provide ongoing support to ensure it stays
              up-to-date and secure.
            </p>
          </div>
        </div>
        <Projects></Projects>
        {/* <h2>Portfolio</h2>
        <p>Explore some of the websites we’ve built for small businesses:</p>
        <div className="portfolio-grid">
          <div className="portfolio-item">
            <img src="/images/site1.jpg" alt="Portfolio Site 1" />
            <p>E-commerce Site for Local Boutique</p>
          </div>
          <div className="portfolio-item">
            <img src="/images/site2.jpg" alt="Portfolio Site 2" />
            <p>Portfolio Website for a Photographer</p>
          </div>
          <div className="portfolio-item">
            <img src="/images/site3.jpg" alt="Portfolio Site 3" />
            <p>Informational Site for a Food Truck</p>
          </div>
        </div> */}
        <h2>Pricing</h2>
        <p>
          We offer flexible pricing plans to suit businesses of all sizes.
          Schedule a consultation to get a personalized quote.
        </p>
        <Link to={"/pricing"}>
          <button className="cta-button">See Our Prices</button>
        </Link>
        <Link to={"/contact"}>
          <button className="cta-button">Schedule a Consultation</button>
        </Link>
      </div>
    </section>
  );
};

export default CustomWebsites;
