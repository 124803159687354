import React from "react";
import "./ServiceDetails.css";
import Projects from "./Projects";
import { Link } from "react-router-dom";

const Consulting = () => {
  return (
    <section className="service-details">
      <div className="header">
        <h1>Brand Strategy Consulting</h1>
        <p>
          Elevate your business with tailored strategies to help you stand out,
          connect with your audience, and grow sustainably.
        </p>
      </div>
      <div className="content">
        <h2>Our Approach</h2>
        <p>
          We believe in practical, results-driven strategies. Our consulting
          focuses on:
        </p>
        <ul className="benefits-list">
          <li> Defining Your Unique Value Proposition</li>
          <li> Identifying and Reaching Your Target Audience</li>
          <li> Crafting Effective Branding and Messaging</li>
          <li> Optimizing Digital Marketing Campaigns</li>
          <li> Creating Scalable Growth Plans</li>
        </ul>
        <h2>Our Process</h2>
        <div className="process-steps">
          <div className="step">
            <h3>1. Discovery Session</h3>
            <p>
              We learn about your business, challenges, and goals to create a
              custom strategy that works for you.
            </p>
          </div>
          <div className="step">
            <h3>2. Strategy Development</h3>
            <p>
              Our team crafts a detailed plan, outlining actionable steps to
              achieve your objectives.
            </p>
          </div>
          <div className="step">
            <h3>3. Implementation Support</h3>
            <p>
              We guide you through executing the strategy, ensuring every aspect
              aligns with your vision.
            </p>
          </div>
          <div className="step">
            <h3>4. Continuous Improvement</h3>
            <p>
              Regular check-ins and updates to refine the approach based on
              results and market trends.
            </p>
          </div>
        </div>

        <Projects></Projects>
        <h2>Consultation Packages</h2>
        <p>
          Get personalized consulting for as low as $500. Contact us to find the
          package that suits your business.
        </p>
        <Link to={"/pricing"}>
          <button className="cta-button">Request a Quote</button>
        </Link>
        <Link to={"/contact"}>
          <button className="cta-button">Schedule a Consultation</button>
        </Link>
      </div>
    </section>
  );
};

export default Consulting;
