import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import About from "./components/About";
import MyWork from "./components/MyWork";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import "./App.css";
import ServicesPage from "./components/ServicesPage";
import BlogLanding from "./components/BlogLanding";
import BlogPostPage from "./components/BlogPostPage";
import PricingPage from "./components/Pricing";
import SEOPage from "./components/SEOPage";
import CustomWebsites from "./components/CustomWebsites";
import Ecommerce from "./components/Ecommerce";
import Consulting from "./components/Consulting";

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/seo-services" element={<SEOPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/my-work" element={<MyWork />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<BlogLanding />} />
        <Route path="/blog/:slug" element={<BlogPostPage />} />{" "}
        {/* <Route path="/" element={<Services />} /> */}
        <Route path="/services/custom-websites" element={<CustomWebsites />} />
        <Route path="/services/ecommerce" element={<Ecommerce />} />
        <Route path="/services/consulting" element={<Consulting />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
